const firebaseConfig = {
  apiKey: "AIzaSyB-vu3W3PvWQlasH2UxgvbfGcCEA1CsPyk",
  authDomain: "typingtester-47fd7.firebaseapp.com",
  projectId: "typingtester-47fd7",
  storageBucket: "typingtester-47fd7.appspot.com",
  messagingSenderId: "810323401063",
  appId: "1:810323401063:web:8f029c8d43a9b7878377c2",
};

export default firebaseConfig;
